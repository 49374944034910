import { useEffect, useState } from "react";
import './admin.css';

function Admin() {
    const [mes, setMes] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [challs, setchalls] = useState(false);

    const [category, setCategory] = useState(false);
    const [diff, setDiff] = useState(false);
    const [name, setName] = useState(false);
    const [desc, setDesc] = useState(false);
    const [points, setPoints] = useState(false);
    const [gain, setGain] = useState(false);
    const [flag, setFlag] = useState(false);
    const [hint, setHint] = useState(false);
    const [keyword, setKeyword] = useState(false);

    const [ToUnlock, setToUnlock] = useState(false);
    const [unlock, setUnlock] = useState(false);

    const [remove, setRemove] = useState(false);
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState(null);

    // Fetch logs from the API
    const fetchLogs = async () => {
        try {
            const response = await fetch('https://api.timtal.net/logs', {
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`
                }
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();
            setLogs(data);
        } catch (err) {
            setError(err.message);
        }
    };
    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    // Fetch logs every 10 seconds
    useEffect(() => {
        fetchLogs(); // Initial fetch
        const interval = setInterval(fetchLogs, 10000); // Fetch every 10 seconds

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        const token = sessionStorage.getItem("token");
    const data = JSON.parse(atob(token.split(".")[1]).toString('utf-8'));
    if(data.username==="HazAdmin" && data.userId=="67613038eec1b27fcaa6cc47"){
        setAdmin(true);
        fetch("https://api.timtal.net/get-challenges", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
            setchalls(data.challenges)
            })
    }
        console.log(data)
        }, []);

        const handleAdd = () =>{
            const data = {
                challenge:{
                "category": category,
                "challengeName": name,
                "challengeDesc": desc,
                "challangeGain": gain,
                "flag": flag,
                "points": points,
                "hintUnlocked":false,
                "hint": hint,
                "difficulty": diff,
                "fails": 0,
                "keywords": keyword,
                "unlocked": false,
                "completed": false,
                "unlocks": []
            }
            }
            const token = sessionStorage.getItem("token");
            fetch("https://api.timtal.net/add-challenge", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setMes(data.message)
            })
        }

        const handleRemove = () =>{
            const data = {
                challengeName: remove
            }
            const token = sessionStorage.getItem("token");
            fetch("https://api.timtal.net/delete-challenge", {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setMes(data.message)
            })
        }

        const handleAddUnlock = () =>{
            const data = {
                unlockerIndex: unlock,
                unlockedIndex: ToUnlock
            }
            const token = sessionStorage.getItem("token");
            fetch("https://api.timtal.net/unlock-challenge", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setMes(data.message)
            })
        }

        const handleRemoveUnlock = () =>{
            const data = {
                unlockerIndex: unlock,
                unlockedIndex: ToUnlock
            }
            const token = sessionStorage.getItem("token");
            fetch("https://api.timtal.net/lock-challenge", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setMes(data.message)
            })
        }

        const unlockScoreboard = () =>{
            const token = sessionStorage.getItem("token");
            fetch("https://api.timtal.net/unlock-scoreboard", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setMes(data.message)
            })
        }

        const lockScoreboard = () =>{
            const token = sessionStorage.getItem("token");
            fetch("https://api.timtal.net/lock-scoreboard", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setMes(data.message)
            })
        }

        const unlockFlagEntry = () =>{
            const token = sessionStorage.getItem("token");
            fetch("https://api.timtal.net/unlock-flags", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setMes(data.message)
            })
        }

        const lockFlagEntry = () =>{
            const token = sessionStorage.getItem("token");
            fetch("https://api.timtal.net/lock-flags", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setMes(data.message)
            })
        }
    return ( <>{admin ? (<><div className="flex"><h3>{mes}</h3><div>
         <select name="category" onChange={(e)=>setCategory(e.target.value)}>
            <option value="Web">Web</option>
            <option value="OSINT">OSINT</option>
            <option value="Linux Terminali">Linux</option>
            <option value="Kriptografi">Kriptografi</option>
        </select>
        <select name="diff"onChange={(e)=>setDiff(e.target.value)}>
            <option value="Kolay">Kolay</option>
            <option value="Orta">Orta</option>
            <option value="Zor">Zor</option>
        </select>
        <input type="number" placeholder="Puan" onChange={(e)=>setPoints(e.target.value)} />
        <input type="text" placeholder="Challange Name" onChange={(e)=>setName(e.target.value)} />
        <input type="text" placeholder="Challange Desc" onChange={(e)=>setDesc(e.target.value)}/>
        <input type="text" placeholder="Challange Gain" onChange={(e)=>setGain(e.target.value)}/>
        <input type="text" placeholder="Challange Flag" onChange={(e)=>setFlag(e.target.value)}/>
        <input type="text" placeholder="Challange Hint"onChange={(e)=>setHint(e.target.value)} />
        <input type="text" placeholder="Challange Keyword" onChange={(e)=>setKeyword(e.target.value)}/>
        <button onClick={handleAdd}>Add Challange</button>
        </div><div>
        Bu challenge : <select name="challstoUnlock" onChange={(e)=>setToUnlock(e.target.value)}>
           {challs ? challs.map((chall,index)=>{
                return <option value={index}>{chall.challengeName}</option>
           }):<></>}
        </select>
        Bu challenge tarafından açılacak:    <select name="unlocks" onChange={(e)=>setUnlock(e.target.value)}>
           {challs ? challs.map((chall,index)=>{
                return <option value={index}>{chall.challengeName}</option>
           }):<></>}
        </select>
        <button onClick={handleAddUnlock}>Açılacağa ekle</button><button onClick={handleRemoveUnlock}>Açılacaktan sil</button>
        </div>
        <div>
        <select name="remove" onChange={(e)=>setRemove(e.target.value)
        }>
           {challs ? challs.map((chall,index)=>{
                return <option value={chall.challengeName} >{chall.challengeName}</option>
           }):<></>}
        </select>
        <button onClick={handleRemove}>Remove</button>
        </div>
        <div>
            <button onClick={unlockScoreboard}>ENABLE SCOREBOARD</button>
            <button onClick={lockScoreboard}>DISABLE SCOREBOARD</button>
            <button onClick={unlockFlagEntry}>ENABLE ENTRY</button>
            <button onClick={lockFlagEntry}>DISABLE ENTRY</button>
        </div>
        <div>
        <h3>Loglar</h3>
        <table>
            <thead>
                <tr>
                    <th className="time">Zaman</th>
                    <th className="ip">IP Adresi</th>
                    <th>Mesaj</th>
                    <th className="eventType">Olay Türü</th>
                </tr>
            </thead>
            <tbody>
                {logs.map((log, index) => (
                    <tr key={index} style={{ backgroundColor: log.eventType === 'AdminLogin' ? '#ffbd6b' : log.eventType === 'UserLogin' ? '#f27bff' : log.eventType === 'UserSignup' ? '#99ff6b' : log.eventType === 'PasswordChange' ? '#6bfaff' : log.eventType === 'HintBuy' ? '#fcff6b' : log.eventType === 'AddChallenge' ? '#f66bff' : log.eventType === 'DeleteChallenge' ? '#ba4fc1' : log.eventType === 'ChallengeBind' ? '#ffb37b' : log.eventType === 'ChallengeUnbind' ? '#cf8f61' : log.eventType === 'ScoreboardLock' ? '#9eff75' : log.eventType === 'ScoreboardUnlock' ? '#84d561' : log.eventType === 'FlagEntryLock' ? '#78b1ff' : log.eventType === 'FlagEntryUnLock' ? '#5f8cc9' : log.eventType === 'FlagCorrect' ? '#9cff6b' : log.eventType === 'FlagIncorrect' ? '#ff6b6b':"#6b73ff" }}>
                        <td className="time">{formatDate(log.date)}</td>
                        <td className="ip">{log.ipAddress}</td>
                        <td>{log.log}</td>
                        <td className="eventType">{log.eventType}</td>
                        
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
        </div></>):(<>FORBIDDEN</>)}</> );
}

export default Admin;