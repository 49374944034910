import { useState } from "react";

function MoneyModal(props) {
  const [style, setStyle] = useState({});
  const modalClass = props.modalActive
    ? "challengeModalMain active"
    : "challengeModalMain";
  const modalBody = props.modalActive ? "modalCard active" : "modalCard";
  const closeModal = () => {
    props.sendDataToParent(false);
    setTimeout(() => {
      setStyle({ animation: " fadeOut .5s linear" });
    }, 500);
    setTimeout(() => {
      setStyle({});
    }, 500);
  };
  return (
    <div>
      <div onClick={closeModal} className={modalClass} style={style}></div>
      <div style={style} className={modalBody}>
        <section className="modal-header">
          

          <p className="modalChallengeName">Yapımcılar</p>
        </section>
        <section className="modal-body">
        <p className="modalDesc">Mentor: Cafer Timur ULUÇ, Mustafa ÖZER</p>
        <p className="modalDesc">Proje Yönetimi: Rüya Melis ÜNVER, Halil Erdem GÜRELLİ</p>
          <p className="modalDesc">Geliştirici: Halil Erdem GÜRELLİ</p>
          <p className="modalDesc">Soru Yazımı: Rüya Melis ÜNVER ,Halil Erdem GÜRELLİ</p>
          <p className="modalDesc">Arayüz Tasarımı: Ali Asaf GÖKER</p>
          <p className="modalDesc">Görsel Tasarımı: Ali Asaf GÖKER, Mustafa Hamza AKDENİZ, Asrın Çınar SULU</p>
         
        </section>
        <section className="modal-flag"></section>
      </div>

    </div>
  );
}

export default MoneyModal;
